<template>
  <div
    class="modal fade"
    id="modalDetail"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <form @submit.prevent="onSubmit">
          <div class="modal-header" :style="{ paddingTop: '1rem' }">
            <div class="modal-title">{{ titleModal }} Fee Marketing</div>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label class="">Laba :</label>
              <div class="d-flex align-item-center">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">Rp</div>
                  </div>
                  <input
                    type="text"
                    class="form-control text-right"
                    v-money="configMoney"
                    placeholder="Minimum"
                    v-model="formData.laba_min"
                    required="true"
                  />
                </div>
                <div class="px-2 pt-2">-</div>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">Rp</div>
                  </div>
                  <input
                    type="text"
                    class="form-control text-right"
                    v-money="configMoney"
                    placeholder="Maximum"
                    v-model="formData.laba_max"
                    required="true"
                  />
                </div>
              </div>
            </div>

            <div class="form-group">
              <label class="">Fee Marketing :</label>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control text-right"
                  placeholder="Masukkan Fee Marketing "
                  v-model="formData.fee_marketing"
                  required="true"
                  v-maska="'#############'"
                />
                <div class="input-group-append">
                  <div class="input-group-text">%</div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>Tanggal Berlaku</label>
              <Datepicker
                v-model="formData.tanggal_berlaku"
                placeholder="Pilih Tanggal"
                :format="format"
                :enableTimePicker="false"
                locale="id-ID"
                selectText="Pilih"
                cancelText="Batal"
                :closeOnScroll="true"
                :autoApply="true"
                required="true"
              ></Datepicker>
            </div>
            <div class="form-group">
              <label for="inputAddress2">Status :</label>
              <Select2
                v-model="formData.status"
                :options="optionStatus"
                placeholder="Pilih Status"
                @change="formChange('status')"
                @select="formChange('status')"
              />
              <div class="form-error" v-if="formError && formError.status">
                {{ formError.status }}
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-save" :disabled="isSubmit">
              <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
              Simpan
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <section class="section">
        <div class="d-flex ml-3 mb-2 align-items-center">
          <div class="mr-auto">
            <ul class="nav nav-pills">
              <li class="nav-item">
                <router-link to="/data-item-penomoran" class="nav-link"
                  >Item Penomoran
                </router-link>
              </li>
              <li
                class="nav-item"
                v-if="checkAccess('setting', 'cru_item_payroll')"
              >
                <router-link to="/data-item-penggajian" class="nav-link"
                  >Item Penggajian</router-link
                >
              </li>
              <li class="nav-item" v-if="checkAccess('setting', 'sync')">
                <router-link to="/data-item-synchronize" class="nav-link"
                  >Sinkronisasi
                </router-link>
              </li>
              <li class="nav-item" v-if="checkAccess('setting', 'sync')">
                <router-link to="/data-fee-marketing" class="nav-link active"
                  >Fee Marketing
                </router-link>
              </li>
              <!-- <li class="nav-item">
                                <router-link to="/data-level-project" class="nav-link">Level Project
                                </router-link>
                            </li> -->
            </ul>
          </div>

          <!-- <form class="search mr-3" @submit.prevent="onSearch">
            <input
              type="text"
              v-model="search"
              @keyup="searchCheck"
              id="searchbox"
              placeholder="Cari..."
              name="search"
            />
            <button type="submit"><i class="fa fa-search"></i></button>
          </form> -->

          <button class="btn-add" @click="detail('')">+ Tambah</button>
        </div>
        <div class="card-table">
          <div class="table-responsive">
            <table
              class="table table-bordered table-striped table-hover"
              id="tableFeeMarketing"
            >
              <thead>
                <tr>
                  <th style="width: 15%">Laba Minimum</th>
                  <th style="width: 20%">Laba Maksimum</th>
                  <th style="width: 20%">Fee Marketing</th>
                  <th style="width: 20%">Tanggal Berlaku</th>
                  <th style="width: 10%">Status</th>
                  <th style="width: 10%" class="text-center">Aksi</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(value, index) in list" :key="index">
                  <td class="text-right">{{ formatMoney(value.laba_min) }}</td>
                  <td class="text-right">{{ formatMoney(value.laba_max) }}</td>
                  <td class="text-right">{{ value.fee_marketing + "%" }}</td>
                  <td>{{ convertDate(value.tanggal_berlaku) }}</td>
                  <td>
                    <div v-if="value.status == 'aktif'" class="green-bedge">
                      Aktif
                    </div>
                    <div v-else class="red-bedge">Nonaktif</div>
                  </td>
                  <td class="text-center">
                    <svg
                      class="detail-icon"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Edit"
                      v-on:click="detail(value)"
                      width="20"
                      height="20"
                      viewBox="0 0 31 31"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.5 25.8301H27.125"
                        stroke="#898989"
                        stroke-width="2.33333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M21.3125 4.52324C21.8264 4.00938 22.5233 3.7207 23.25 3.7207C23.6098 3.7207 23.9661 3.79158 24.2986 3.92928C24.631 4.06698 24.9331 4.26881 25.1875 4.52324C25.4419 4.77768 25.6438 5.07974 25.7815 5.41217C25.9192 5.74461 25.99 6.10091 25.99 6.46074C25.99 6.82057 25.9192 7.17687 25.7815 7.50931C25.6438 7.84175 25.4419 8.14381 25.1875 8.39824L9.04167 24.5441L3.875 25.8357L5.16667 20.6691L21.3125 4.52324Z"
                        stroke="#898989"
                        stroke-width="2.33333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../components/Sidebar.vue";
import NavbarDashboard from "../../../components/Navbar.vue";
import Datepicker from "vue3-date-time-picker";
import { ref } from "vue";
import Select2 from "vue3-select2-component";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import $ from "jquery";
import { checkModuleAccess, cksClient, showAlert } from "../../../helper";
import { maska } from "maska";
import { VMoney } from "v-money";
import {
  get_FeeMarketingList,
  post_FeeMarketingSave,
} from "../../../actions/setting/feeMarketing";
import moment from "moment";
import "moment/locale/id";
export default {
  name: "dataDepartement",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    Datepicker,
    Select2,
    // TableLite
  },

  directives: {
    maska,
    money: VMoney,
  },

  data() {
    return {
      checkAccess: checkModuleAccess,
      isSearch: false,
      search: "",
      titleModal: "Tambah",
      isSubmit: false,
      optionStatus: [
        { id: "aktif", text: "Aktif" },
        { id: "tidak", text: "Nonaktif" },
      ],
      formData: {
        session: "",
        laba_min: "",
        laba_max: "",
        fee_marketing: "",
        tanggal_berlaku: "",
        status: "",
        id_company: cksClient().get("_account").id_company,
      },
      list: [],
      id_company: cksClient().get("_account").id_company,
      id_divisi: cksClient().get("_account").id_divisi,
      dataTable: "",
      configMoney: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false /* doesn't work with directive */,
      },
    };
  },

  setup() {
    const date = ref(new Date());

    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
      const month = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      const day = date.getDate();
      const monthText = month[date.getMonth()];
      const year = date.getFullYear();

      return `${day} ${monthText} ${year}`;
    };
    return {
      date,
      format,
    };
  },
  mounted() {
    this.tooltip();
  },
  created() {
    this.getList();
  },
  beforeUnmount() {
    if (this.dataTable) {
      this.dataTable.destroy();
      this.dataTable = "";
    }
  },
  methods: {
    tooltip() {
      $('[data-toggle="tooltip"]').tooltip();
    },

    formatMoney(a) {
      if (!a) {
        return 0;
      }
      return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    convertDate(string) {
      return moment(string).format("DD MMMM YYYY");
    },

    searchCheck: function () {
      if (!this.search && this.isSearch) {
        this.isSearch = false;
        this.getList();
      }
    },
    onSearch: function () {
      if (this.search || this.isSearch) {
        this.isSearch = true;
        this.getList();
      }
      if (!this.search && this.isSearch) {
        this.isSearch = false;
      }
    },
    async getList() {
      await get_FeeMarketingList(
        {
          id_company: this.id_company,
          search: this.search,
        },
        (res) => {
          this.list = res.list;
          if (this.dataTable) {
            this.dataTable.destroy();
          }
          setTimeout(() => {
            this.dataTable = $("#tableFeeMarketing").DataTable({
              info: false,
            });
            this.tooltip();
          }, 1000);
        }
      );
    },

    detail(data) {
      if (data) {
        this.formData.session = data.session;
        this.formData.laba_min = this.formatMoney(data.laba_min);
        this.formData.laba_max = this.formatMoney(data.laba_max);
        this.formData.fee_marketing = data.fee_marketing;
        this.formData.tanggal_berlaku = data.tanggal_berlaku;
        this.formData.status = data.status;
        this.titleModal = "Edit";
      } else {
        this.formData.session = "";
        this.formData.laba_min = "";
        this.formData.laba_max = "";
        this.formData.fee_marketing = "";
        this.formData.tanggal_berlaku = "";
        this.formData.status = "aktif";
        this.titleModal = "Tambah";
      }
      $('[data-toggle="tooltip"]').tooltip("hide");
      $("#modalDetail").modal("show");
    },
    postData() {
      this.isSubmit = true;
      var data = this.formData;
      data.tanggal_berlaku = moment(this.formData.tanggal_berlaku).format(
        "YYYY-MM-DD"
      );
      data.laba_min = this.formData.laba_min.toString().split(".").join("");
      data.laba_max = this.formData.laba_max.toString().split(".").join("");
      post_FeeMarketingSave(
        data,
        (res) => {
          this.getList();
          var msg =
            res.response_code == 201
              ? "Data Fee Marketing berhasil dicatat"
              : "Data Fee Marketing berhasil diperbarui";
          this.isSubmit = false;
          showAlert(this.$swal, {
            title: "BERHASIL!",
            msg: msg,
          });
        },
        () => {
          this.isSubmit = false;
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Terjadi kesalahan, silakan ulangi kembali nanti",
            showCancelButton: true,
            showConfirmButton: false,
          });
        }
      );
    },
    onSubmit() {
      showAlert(this.$swal, {
        title: "PERHATIAN!",
        msg: this.formData.session
          ? "Apakah anda yakin, Tindakan ini akan menyimpan semua perubahan yang anda lakukan?"
          : "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Lanjutkan",
        onSubmit: () => {
          $("#modalDetail").modal("hide");
          this.postData();
        },
      });
    },
  },
};
</script>

<style scoped src="../../../assets/css/style.css"></style>

<style scoped src="../../../assets/css/custom.css"></style>

<style scoped>
.card-table {
  margin-top: 0px;
}

.strip {
  border: 1px solid #e3e3e3;
  margin: 0px 10px;
}
</style>
