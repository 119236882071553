import { API } from "../../../constans/Url"
import { apiClient } from "../../../helper"


const get_FeeMarketingList = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.FEE_MARKETING.LIST, params).then(res => {
    if (res.status == 200) {
      callbackSuccess(res.data)
    }else {
      callbackError(res.data)
    }
  }).catch(err =>{
    callbackError(err)
  })
}
const post_FeeMarketingSave = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.FEE_MARKETING.SAVE, params).then(res => {
    if (res.status == 200 || res.status === 201) {
      callbackSuccess(res.data)
    }else {
      callbackError(res.data)
    }
  }).catch(err =>{
    callbackError(err)
  })
}


export{
  get_FeeMarketingList,
  post_FeeMarketingSave
}